@tailwind base;
@layer base {
  h1 {
    @apply text-3xl;
  }
  h2 {
    @apply text-2xl;
  }
  h3 {
    @apply text-xl;
  }
  h2 {
    @apply text-lg;
  }
  a {
    @apply text-blue-600 underline;
  }
}
@tailwind components;
@tailwind utilities;
